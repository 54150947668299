export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: 'center',
  searchMenuSpan: 6,
  align: 'center',
  tip: false,
  selection: true,
  editBtn: false,
  delBtn: false,
  addBtn: false,
  column : [{
    fixed: true,
    label: '姓名',
    prop: 'personName',
    span:24,
  },
  {
    fixed: true,
    label: '设备编号',
    prop: 'deviceNo',
    span:24,
  },
{
    fixed: true,
    label: '打卡时间',
    prop: 'reportTime',
    span: 24
  },{
    fixed: true,
    label: '地点',
    prop: 'address',
    // hide: true,
    span: 24
  }]
}
