const outOption = {
  title: "打卡记录",
  column: [
    {
      label: '姓名',
      prop: 'personName',
    },
    {
      label: '设备编号',
      prop: 'deviceNo',
    },
    {
      label: '打卡时间',
      prop: 'reportTime',
    },
    {
      label: '地点',
      prop: 'address',
    },
  ],
};
export default outOption
