<template>
  <div class="clock-container">
    <Jump :jumpList="jumpList" />
    <NavMenu activeUrl="/setting/application" />
    <div class="clock-wrap">
      <div class="clock-menu">
        <chilMenu></chilMenu>
      </div>
      <div class="clock-main">
        <div class="search" style="padding-left: 40px">
          <div>
            姓名：
            <el-input placeholder="请输入姓名" v-model="search.personName" />
          </div>
          <div style="padding-left: 20px">
            设备编号：
            <el-input placeholder="请输入设备编号" v-model="search.deviceNo" />
          </div>

          <el-button
            class="filter-item"
            type="primary"
            size="default"
            icon="el-icon-search"
            @click="page.currentPage=1;getList(page, search)"
            >搜索
          </el-button>
          <el-button class="filter-item" size="default" @click="reset"
            >清空
          </el-button>
        </div>
        <avue-crud
          ref="crud"
          :option="option"
          :data="list"
          :page.sync="page"
          v-model="form"
          :table-loading="listLoading"
          @on-load="getList"
          @size-change="sizeChange"
          @current-change="currentChange"
          @selection-change="selectionChange"
        >
          <template slot="position" slot-scope="scope">
            {{ scope.row.longitude }},{{ scope.row.latitude }}
          </template>
          <template slot="menuRight">
            <el-button type="primary" size="small" @click="exportXlsx()">
              导出
            </el-button>
          </template>
          <template slot="menu" slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="openLocation(scope.row)"
              v-auth="['elec_clock_view']"
            >
              查看地点
            </el-button>
          </template>
        </avue-crud>
      </div>
    </div>

    <Location ref="location" />
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../../../setting/navMenu.vue";
import chilMenu from "../../chilMenu.vue";
import Location from "./Location";
import { tableOption } from "./tableOption";
import { electronPunchRecordPage } from "../../../../api/elec/punchRecord";
import { exportXlsx } from "../../../../components/processingXlsx";
import outOption from "./outOption";
export default {
  name: "clockIn",
  data() {
    return {
      activeUrl: "clockInRecord",
      outOption,
      jumpList: [
        { name: "首页", },
        {name:'系统管理'},
        { name: "电子设备", },
        { name: "打卡记录", },
      ],
      admineleclist: null, //电子设备管理
      elecrolelist: null,
      elecwarnlist: null,
      elecuserlist: null,
      elecclocklist: null,
      search: {},
      selection: [],
      exportLoading: false,
      listLoading: false,
      option: tableOption,
      form: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false, // 是否倒序
      },
      list: [],
    };
  },
  components: {
    Jump,
    NavMenu,
    Location,
    chilMenu
  },
  mounted() {
    let permissions = this.$store.state.app.permissions;
    permissions.forEach((item) => {
      if (item == "electron_device_view") {
        this.admineleclist = 1;
      }
      if (item == "electron_alert_record_view") {
        this.elecwarnlist = 1;
      }
      if (item == "electron_punch_record_view") {
        this.elecclocklist = 1;
      }
      if (item == "electron_role_view") {
        this.elecrolelist = 1;
      }
      if (item == "electron_person_view") {
        this.elecuserlist = 1;
      }
    });
  },
  computed: {
    exportData() {
      const data = JSON.parse(JSON.stringify(this.selection));
      return data.map((item) => {
        item.position = `${item.longitude},${item.latitude}`
        return item;
      });
    },
  },
  methods: {
    async exportXlsx() {
      if (!this.selection.length) return this.$message.warning("请选择数据");
      this.exportLoading = true;
      const data = this.exportData;
      const res = await exportXlsx(data, this.outOption).finally(
        () => (this.exportLoading = false)
      );
      console.log(res);
      this.$message.success("导出成功！");
    },
    selectionChange(val) {
      this.selection = val;
    },
    tabClick(url) {
      this.$router.push(url);
    },
    openLocation(row) {
      this.$refs.location.show([row.longitude, row.latitude]);
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList();
    },
    reset() {
      this.search = {};
      this.page.currentPage = 1;
    },
    async getList() {
      const res = await electronPunchRecordPage({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...this.search,
      });
      this.list = res.data.records;
      this.page.total = res.data.total;
    },
  },
};
</script>
<style lang="scss">
.el-menu-e {
  height: 670px;
}
.clock-container {
  .clock-wrap {
    display: flex;
    .clock-menu {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
    .clock-main {
      background: transparent;
    }
  }

  .clock {
    height: 100%;
    &__main {
      .el-card_body {
        padding-top: 0;
      }
    }
  }
  .el-card {
    background-color: transparent;
    border: none;
  }

  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.search {
  display: flex;
  margin-top: 15px;
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    margin-right: 10px;
    .el-range-input {
      background-color: #06193c;
      color: rgba(163, 214, 255, 0.36) !important;
    }
  }
  .el-date-editor .el-range-separator {
    color: rgba(163, 214, 255, 0.76);
  }
  .el-date-editor .el-range__icon {
    color: rgba(163, 214, 255, 0.76);
  }
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
}
</style>
